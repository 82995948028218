<template>
  <div class="tobeCenter">
    <h2>To be updated</h2>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tobeCenter {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  h2 {
    // color: orange;
    // .icon {
    //   color: orange;
    //   font-size: 35px;
    // }
  }
}
</style>